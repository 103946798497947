import React from 'react';
import styles from "./index.module.scss";
import BlurEffectImage from "../../BlurEffectImage/BlurEffectImage";
import logo from "../../../img/logo.jpg";
import linkIco from "../../../img/link.svg";
import {NavLink} from "react-router-dom";
import Footer from "./Footer";
import adsImage1 from "../../../img/ads/Чемпионат и Первенство Приволжского федерального округа.jpg";

const Menu = () => {
    const [isMenuOpened, setIsMenuOpened] = React.useState(false);

    const toggleMenu = () => {
        setIsMenuOpened(!isMenuOpened);
    }
    return (
        <div className={`${styles.menu} ${isMenuOpened && styles.menu_active}`}>
            <div className={styles.menu__top}>
                <NavLink
                    to="/news"
                    className={({isActive}) => (isActive ? `${styles.menu__item} ${styles.menu__item_active}` : styles.menu__item)}
                    onClick={() => setIsMenuOpened(false)}
                >
                    <div className={styles.menu__logo}>
                        <BlurEffectImage src={logo} alt="логотип СФСО"/>
                    </div>
                </NavLink>
                <button onClick={toggleMenu}
                        name="burger-menu"
                        title="Menu"
                        className={`${styles.menu__button} ${isMenuOpened && styles.menu__button_active}`}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>

            <div className={styles.menu__content}>
                <nav className={styles.menu__nav}>
                    <NavLink
                        to="/news"
                        className={({isActive}) => (isActive ? `${styles.menu__item} ${styles.menu__item_active}` : styles.menu__item)}
                        onClick={toggleMenu}
                    >
                        Новости
                    </NavLink>
                    <NavLink
                        to="/articles"
                        className={({isActive}) => (isActive ? `${styles.menu__item} ${styles.menu__item_active}` : styles.menu__item)}
                        onClick={toggleMenu}
                    >
                        Статьи
                    </NavLink>
                    <NavLink
                        to="/maps"
                        className={({isActive}) => (isActive ? `${styles.menu__item} ${styles.menu__item_active}` : styles.menu__item)}
                        onClick={toggleMenu}
                    >
                        Карты
                    </NavLink>
                    <NavLink
                        to="/federation"
                        className={({isActive}) => (isActive ? `${styles.menu__item} ${styles.menu__item_active}` : styles.menu__item)}
                        onClick={toggleMenu}
                    >
                        Федерация
                    </NavLink>
                    <a className={styles.menu__item} href="https://o-saratov.ru/api/media/uploads/Проект._областного_Календаря_2025_.pdf" target="_blank"
                       rel="noreferrer">Календарь
                        {/* fixme: ico color*/}
                        <img className="ico" src={linkIco} alt="Link ico"/>
                    </a>
                </nav>
                {/* todo: надо придумать новый способ отображения инфы о соревах, текст с картинки плохо читается */}
                <div className={styles.ads}>
                    <NavLink to={`/news/${78}`} className={styles.articleLink} onClick={toggleMenu}>
                        <BlurEffectImage src={adsImage1}
                                         alt="Чемпионат и Первенство Приволжского федерального округа Традиционные многодневные соревнования «Гран-При Волга» памяти Геннадия Ачкасова"/>
                    </NavLink>
                </div>
                <div className={styles.menu__bottom}>
                    <Footer toggleMenu={toggleMenu}/>
                </div>
            </div>
        </div>
    );
};

export default Menu;